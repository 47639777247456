import React from 'react';
import styled from 'styled-components';
import {Formik, Field, Form, ErrorMessage} from "formik";

import {pixels as breakpoints} from '../config/breakpoints';
import Layout from "../component/Layout/Layout";
import {encode} from '../utils';
import resources from '../resources';

const {copy} = resources;

const EmailWrapper = styled.div`
    padding: 20px 2em 10px;
    @media (min-width: ${breakpoints.tablet}) {
        padding: 100px 7em 50px;
    }
`

const Heading = styled.div`
/* Terrazzo is next big: */
  font-size: 40px;
  @media (min-width: ${breakpoints.tablet}) {
    font-size: 90px;
    color: #212121;
    letter-spacing: 0;
    line-height: 90px;
    margin-bottom: 70px;
  }
`

const StyledForm = styled(Form)`
display: contents;
`

const Input = styled(Field)`
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid #C7CCDA;
  margin-bottom: 30px;
  font-size: 18px;
  color: #6A7581;

  &:hover, &:focus, &:active {
    border-bottom: 1px solid #6A7581;
    color: #212121;
  }
`
const Button = styled.button`
  cursor: pointer;
  padding: 20px;
  color: white;
  background-color: #31576e;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  
  &:hover, &:focus {
    background-color: #555555
  }
`

const EmailPage = ({location}) => {
  const params = new URLSearchParams(location.search);
  const email = params.get("email");
  const name = params.get("name");
  // const ref = params.get("ref");

  return (
    <Layout>
      <EmailWrapper>
        <Heading>Request a callback</Heading>
        <Formik
          initialValues={{name: name || '', email: email || '', phone: ''}}
          validate={(values) => {
            let errors = {};
            if (!values.email) {
              errors.email = 'Email is required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email address';
            }
            if (!values.phone) {
              errors.phone = 'Phone is required'
            }  else if (
              !/^\d{12}$/i.test(values.phone)
            ) {
              errors.phone = 'Invalid phone number';
            }

            if(!values.name) {
              errors.name = 'Name is required'
            }
            console.log('there are errors',errors)
            return errors;
          }}
          onSubmit={async (values, {setSubmitting, resetForm}) => {
            fetch("/", {
              method: "POST",
              headers: {"Content-Type": "application/x-www-form-urlencoded"},
              body: encode({
                "form-name": "callback",
                ...values
              })
            })
              .then(() => {
                alert('Success');
                resetForm()
              })
              .catch(() => {
                alert('Error');
              })
              .finally(() => setSubmitting(false))
          }}
        >
          {({isSubmitting}) => (
            <StyledForm name="callback" data-netlify="true" netlify-honeypot="bot-field">
              <input type="hidden" name="form-name" value="callback" />
              <p style={{visibility: 'hidden ', margin: 0}}>
                <label>Don’t fill this out if you’re human: <input name="bot-field"/></label>
              </p>
              <Input name="name" type="text" placeholder="First and Surname"/>
              <p style={{color: 'red', margin:0}}>
                <ErrorMessage name="email"/>
              </p>
              <Input name="email" type="email" placeholder="Email address"/>
              <p style={{color: 'red', margin:0}}>
                <ErrorMessage name="phone"/>
              </p>
              <Input name="phone" type="text" placeholder="Phone number"/>
              <p>
                <Button type="submit" disabled={isSubmitting}>{copy.contactPage.button}</Button>
              </p>
            </StyledForm>
            )}
            </Formik>
      </EmailWrapper>
    </Layout>
  )
}
export default EmailPage